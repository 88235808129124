.table.tickets .line > .number {
	width: 10%;
	min-width: 10%;
    max-width: 10%;
	font-weight: bold;
}

.table.tickets .line  a {
	color: inherit;
	font-style: inherit;
	text-decoration: inherit;
}

.table.tickets .line > .title {
	width: calc(31% - 50px);
	min-width: calc(31% - 50px);
	max-width: calc(31% - 50px);
}
.table.tickets .line > .title > .text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.table.tickets .line > .type {
	width: 20%;
	min-width: 20%;
	max-width: 20%;
}
.table.tickets .line > .type > .text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.table.tickets .line > .priority,
.table.tickets .line > .state {
	width: 12%;
	min-width: 12%;
	max-width: 12%;
}

.table.tickets .line > td.priority,
.table.tickets .line > td.state {
	color: black;
	font-weight: bold;
}

.table.tickets .line > .date {
	width: 15%;
	min-width: 15%;
	max-width: 15%;
}
