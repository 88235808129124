.overOptions {
	visibility: hidden;
	position: fixed;
	z-index: 6;
	overflow-x: hidden;
	overflow-y: auto;
	width: 230px;
	max-width: 230px;
	max-height: 300px;
	border-radius: 3px;
	border: var(--borderColorOptions);
	background-color: var(--backgroundColorOptions);
	box-shadow: var(--boxShadowOptions);
	opacity: 0;
	filter: alpha(opacity=0);
	transition: all 0.15s;
}
.overOptions.userOptions {
	width: 300px;
	max-width: 300px;
    top: 15px;
    left: 96px;
}
.overOptions.userOptions.show {
    left: 76px;
}
.overOptions.prospectOptions {
    right: 15px;
    top: 185px;
}
.overOptions.prospectOptions.show {
    top: 165px;
}
.overOptions.licenseOptions {
	position: absolute;
	width: 280px;
	max-width: 280px;
	margin-top: 30px;
}
.overOptions.licenseOptions.show {
	margin-top: 15px;
}
.overOptions.accountOptions {
	position: absolute;
	width: 280px;
	max-width: 280px;
	margin-top: 30px;
}
.overOptions.accountOptions.show {
	margin-top: 15px;
}
.overOptions.deviceOptions {
	position: absolute;
	margin-top: 30px;
}
.overOptions.deviceOptions.show {
	margin-top: 15px;
}
.overOptions.ticketOptions {
	position: absolute;
	margin-top: 30px;
}
.overOptions.ticketOptions.show {
	margin-top: 15px;
}
.overOptions.show {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}

.overOptions .options {
	width: 100%;
	margin: 0;
	padding: 0;
}
.overOptions .options a {
	text-decoration: none;
	color: inherit;
}
.overOptions .options .item {
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 15px;
	text-align: left;
	font-size: 10pt;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 20px;
	color: var(--textColorOptionItems);
	cursor: pointer;
	transition: all 0.15s;
}
.overOptions .options .item.locked {
	opacity: .5;
	filter: alpha(opacity=50);
}
.overOptions .options .item:hover {
	background-color: var(--backgroundColorOptionItemsHover);
}
.overOptions .options .item.red {
	color: var(--textColorOptionItemsRed);
}
.overOptions .options .item.red:hover {
	background-color: var(--backgroundColorOptionItemsRed);
}
