.table.partnershipUser .line > .lastname {
	width: 20%;
    max-width: 20%;
}
.table.partnershipUser .line > .email {
	width: calc(60% - 150px);
    max-width: calc(60% - 150px);
}
.table.partnershipUser .line > .member {
	width: 100px;
	max-width: 100px;
}
