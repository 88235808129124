.item {
	width: 100%;
	height: 65px;
	margin: 0;
	cursor: pointer;
	transition: all 0.15s;
}
.item.selected {
	border-right: var(--borderItemMenuSelected);
}
.item.project {
	border-top: var(--borderMenu);
}

.item svg {
	width: 21px;
	height: 21px;
	margin: 22px;
	transition: all 0.15s;
}
.item.selected svg {
	fill: var(--mainColor);
}
.item:hover svg {
	fill: var(--mainColorAlpha);
}
