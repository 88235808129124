.table.client .line  a {
	color: inherit;
	font-style: inherit;
	text-decoration: inherit;
}
.table.client .line > .company {
	width: 20%;
    max-width: 20%;
	font-weight: bold;
}
.table.client .line > .store {
	width: calc(35% - 50px);
	max-width: calc(35% - 50px);
	font-weight: bold;
}
.table.client .line > .postalCode {
	width: 10%;
    max-width: 10%;
}
.table.client .line > .city {
	width: 20%;
    max-width: 20%;
}
.table.client .line > .country {
	width: 15%;
    max-width: 15%;
}
