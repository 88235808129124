.table.prospect .line > .company {
	width: 20%;
    max-width: 20%;
}
.table.prospect .line > .store {
	width: calc(38% - 50px);
	max-width: calc(38% - 50px);
}
.table.prospect .line > .postalCode {
	width: 7%;
    max-width: 7%;
}
.table.prospect .line > .city {
	width: 15%;
    max-width: 15%;
}
.table.prospect .line > .country {
	width: 10%;
    max-width: 10%;
}
.table.prospect .line > .prospectPhase {
	width: 15%;
    max-width: 15%;
	font-weight: bold;
}
.table.prospect .line > .prospectState {
	width: 10%;
    max-width: 10%;
	font-weight: bold;
}
.table.prospect .line > td.prospectPhase,
.table.prospect .line > td.prospectState {
	color: var(--textColorReverse);
}
.table.prospect .line > .prospectState.ACTIVE {
	background-color: var(--mainColor);
}
.table.prospect .line > .prospectState.STANDBY {
	background-color: var(--warningColor);
}
.table.prospect .line > .prospectState.EJECT {
	background-color: var(--errorColor);
}
