.titleTable {
	display: block;
	margin: 20px 0 0 0;
	padding: 0 20px;
	font-size: 11pt;
	line-height: 24px;
	font-weight: bold;
	text-transform: uppercase;
	color: var(--textColorInput);
}

.wrapTable {
	display: block;
	width: 100%;
	height: calc(100vh - 170px);
	max-height: calc(100vh - 170px);
	margin: 0;
	padding: 0;
}
.wrapTable.prospectwrap {
	height: calc(100vh - 250px);
	max-height: calc(100vh - 250px);
}
.wrapTable.clientwrap {
	height: calc(100vh - 180px);
	max-height: calc(100vh - 180px);
}
.wrapTable.productwrap {
	height: calc(100vh - 180px);
	max-height: calc(100vh - 180px);
}
.wrapTable.tableSetupLicenses,
.wrapTable.tableSetupAccounts,
.wrapTable.tableSetupDevices {
	width: calc(100% - 30px);
	height: auto;
	max-height: none;
	margin: 15px;
	background-color: var(--backgroundColorTer);
	border-radius: 3px;
}

.wrapTable .group {
	width: calc(100% - 32px);
	margin: 15px;
	border-radius: 5px;
	border: 1px solid var(--mainColor);
	background-color: var(--boxColor);
}
.wrapTable .group.margin {
	margin-top: 20px;
}

.wrapTable .table {
	display: table;
	width: 100%;
	height: 35px;
	margin: 0;
	padding: 0;
	border-collapse: collapse;
	border: none;
}
.wrapTable .table.borderBottom {
	border-bottom: var(--borderTable);
}
.wrapTable .table tr th {
	background-color: var(--whityColor);
}
.wrapTable .table tr th, .table tr td {
	padding: 12px 10px;
}
.wrapTable .table tr th:first-child, .table tr td:first-child {
	padding-left: 20px;
}
.wrapTable .table tr th {
	color: var(--textColor);
	font-size: 10pt;
}
.wrapTable .table tr td {
	color: var(--textColor);
	font-size: 10pt;
}
.wrapTable .table tr th.left, .wrapTable .table tr td.left {
	text-align: left;
}
.wrapTable .table tr th.center, .wrapTable .table tr td.center {
	text-align: center;
}
.wrapTable .table tr th.right, .wrapTable .table tr td.right {
	text-align: right;
}
.wrapTable .table:not(.borderBottom) tr {
	transition: all 0.10s;
}
.wrapTable .table:not(.borderBottom) tr:not(.button):not(.noHover):hover {
	background-color: var(--backgroundColorTRHover);
}

.wrapTable .list {
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	max-height: calc(100vh - 205px);
}

