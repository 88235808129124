.overbox.NewContract .elevator {
	max-height: calc(100vh - 313px);
}

.NewContract > .steps {
	display: flex;
	flex-flow: row;
	width: calc(100% - 300px);
	margin: 0 auto;
	padding: 20px 0 10px 0;
}

.NewContract > .steps > svg {
	display: block;
	width: 40px;
	height: 40px;
	fill: white;
	transition: all 0.15s;
}
.NewContract > .steps > svg.done {
	fill: var(--mainColor);
}

.NewContract > .steps > .line {
	display: block;
	overflow: hidden;
	width: calc(100% - 60px);
	height: 2px;
	margin: 19px 20px;
	padding: 0;
	border-radius: 1px;
	background-color: #1D1D1D;
}
.NewContract > .steps > .line > .bar {
	display: block;
	width: 0;
	height: 2px;
	margin: 0;
	padding: 0;
	background-color: var(--mainColor);
	transition: all 0.7s;
}
.NewContract > .steps > .line > .bar.done {
	width: 100%;
}
