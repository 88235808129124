.globalWarning {
	z-index: 999;
	position: absolute;
	bottom: 20px;
	right: 20px;
	width: calc(100% / 3);
	margin: 0;
	padding: 15px 15px 15px 20px;
	font-size: 10pt;
	font-weight: bold;
	line-height: 24px;
	text-align: left;
	word-wrap: break-word;
	color: white;
	border-radius: 2px;
	border-left: 5px solid var(--warningColor);
	color: var(--warningColor);
	background-color: var(--boxColor);
	box-shadow: 1px 1px 3px black;
	cursor: pointer;
	transition: 0.15s all;
}
