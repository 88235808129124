.wrapTable.tickets {
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
}
