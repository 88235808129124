.table.prospectPhase .line > .name {
	width: calc(100% - 92px);
	max-width: calc(100% - 92px);
}

.table.prospectPhase .line > .color {
	width: 42px;
	max-width: 42px;
}
.table.prospectPhase .line > .color > .puce {
	display: block;
	overflow: hidden;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
	border-radius: 10px;
	box-shadow: 0 0 1px rgb(100, 100, 100);
}

.table.prospectPhase .line > .action {
	width: 30px;
}

.table.prospectPhase .line:hover > .action .access {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}
.table.prospectPhase .line > .action .access {
	width: 30px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
	transition: all 0.15s;
}
.table.prospectPhase .line > .access svg {
	width: 30px;
	transition: all 0.15s;
}
.table.prospectPhase .line > .access:hover svg {
	fill: var(--mainColor);
}
