.line.button {
	color: white;
	background-color: var(--backgroundTrLineButton);
	cursor: pointer;
}
.line.button:hover {
	background-color: var(--backgroundTrLineButtonHover);
}
.line.button td {
	padding: 10px 0;
	text-align: center;
	font-weight: bold;
}

.line a {
	color: inherit;
	text-decoration: inherit;
}

.line .action {
	width: 50px;
}

.line:hover .action .access {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}
.line .action .access {
	width: 50px;
	opacity: 0;
	filter: alpha(opacity=0);
	transition: all 0.15s;
}
.line .action .access.visible {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}
.line .access svg {
	display: block;
	margin: 0 auto;
	width: 14px;
	height: 14px;
	cursor: pointer;
	transition: all 0.15s;
}
.line .access.visible svg {
	width: 18px;
	height: 18px;
}
.line .access:hover svg {
	fill: var(--mainColor);
}
