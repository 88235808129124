.site {
	min-width: 100%;
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	max-height: 100vh;
	margin: 0;
	padding: 0;
	background-color: var(--backgroundColor);
}
