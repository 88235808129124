.loader {
	display: block;
}
.loader.hide {
	display: none;
}
.loader.logWait {
	width: 70px;
	height: 70px;
	margin: 70px auto;
}
.loader.formWait {
	width: 30px;
	height: 30px;
	margin: 100px auto;
}
.loader.logWaitLine {
	width: 24px;
	height: 24px;
	margin: 10px auto;
	padding: 10px 0;
}
.loader.submitLogin {
	clear: both;
	float: right;
	width: 20px;
	height: 20px;
	margin: 9px 0 0 15px;
	padding: 0;
}
.loader.optionLoader {
	clear: both;
	float: right;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
}
.loader.submitLogout {
	float: right;
	width: 20px;
	height: 20px;
	margin: 0 30px 0 0;
	padding: 0;
}
.loader.restGET {
	width: 70px;
	height: 70px;
	margin: 70px auto;
}
.loader.restPUT {
	float: right;
	width: 24px;
	height: 24px;
	margin: 20px 12px 0 0;
	padding: 0;
}
.loader.submitForm {
	clear: both;
	float: right;
	width: 20px;
	height: 20px;
	margin: 9px 0 0 15px;
	padding: 0;
}
.loader.searchBarLoading {
	float: right;
	width: 24px;
	height: 24px;
	margin: -32px 15px 0 0;
	padding: 0;
}
.loader.searchBarLoading.lessMarginRight {
	margin-right: 10px;
}
.loader.optionLine {
	width: 24px;
	height: 24px;
	margin: 0 auto;
	padding: 0;
}
.loader.line {
	float: left;
	width: 16px;
	height: 16px;
	margin: 0 0 0 10px;
	padding: 0;
}
.loader.submitComment {
	position: absolute;
	float: right;
	right: 25px;
	bottom: 15px;
	width: 40px;
	height: 40px;
	margin: 0;
	padding: 0;
}

.overlayer {
	position: fixed;
	z-index: 100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	transition: all 0.15s;
	animation: opacityUp 0.3s ease;
}
.overlayer {
	position: fixed;
	z-index: 100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	transition: all 0.15s;
	animation: opacityUp 0.3s ease;
}
.overlayer.hover {
	z-index: 103;
}

.wrapOverbox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100vh;
	max-height: 100%;
	margin: 0;
	padding: 0;
}
.wrapOverbox.hover {
	z-index: 104;
}

.overbox {
	z-index: 101;
	position: fixed;
	height: auto;
	max-height: calc(100% - 30px);
	margin: 0;
	padding: 0;
	background-color: var(--backgroundColor);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
	transition: all 0.15s;
	animation: boxUp 0.3s ease;
}
.overbox.hover {
	z-index: 105;
}
.overbox.sheet {
	overflow-x: hidden;
	overflow-y: auto;
	width: calc(100% - 50px);
	height: calc(100% - 50px);
	min-width: calc(100% - 50px);
	min-height: calc(100% - 50px);
	max-width: calc(100% - 50px);
	max-height: calc(100% - 50px);
	border-radius: 5px;
}
.overbox.newItem {
	width: 50%;
	max-width: 50%;
}
.overbox.newItemFix {
	width: 550px;
	max-width: 550px;
}
.overbox.newItemSmall {
	width: 450px;
	max-width: 450px;
}
.overbox.yesOrNo {
	width: 35%;
}
.overbox.choices {
    width: 400px;
}
.overbox.password {
    width: 450px;
}
.overbox.radio {
    width: 430px;
}
.overbox.search {
    width: 500px;
}
.overbox.subList {
	width: 300px;
}

.overbox .cross {
	z-index: 999;
	position: absolute;
	top: 4px;
	right: 4px;
	margin: 0;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
.overbox .cross > svg {
	width: 18px;
	height: 18px;
	margin: 11px;
	transition: all 0.15s;
	fill: var(--textColor);
}
.overbox .cross:hover > svg {
	transform: rotate(180deg);
	fill: var(--mainColor);
}

.overbox .title {
	margin: 20px 0 40px 0;
	padding: 0;
	color: var(--textColor);
	text-align: center;
	font-size: 11pt;
	font-weight: bold;
	text-transform: uppercase;
}
.overbox .text {
	margin: 15px 0 30px 0;
	padding: 0;
	font-size: 11pt;
	line-height: 26px;
	color: var(--textColor);
}

.overbox .elevator {
	display: block;
	clear: both;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	margin: 0;
	padding: 0;
}

.listChoices {
    display: block;
    position: absolute;
    z-index: 102;
    overflow-x: hidden;
    overflow-y: auto;
    width: 250px;
    max-height: 150px;
	left: 0;
	top: 0;
    margin: 0;
    padding: 0;
    background-color: var(--backgroundColorListChoices);
}
.listChoices .choice {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: var(--borderBottomListChoicesChoice);
    cursor: pointer;
    transition: all 0.15s;
}
.listChoices .choice .main {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    padding: 0 15px;
    color: white;
    font-size: 9pt;
    line-height: 36px;
}
.listChoices .choice:last-child {
    border-bottom: none;
}
.listChoices .choice:hover {
    background-color: var(--backgroundColorListChoicesChoiceHover);
}

.colorBox {
	display: block;
	position: absolute;
	z-index: 102;
	width: 200px;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
	border-radius: 5px;
	background-color: var(--backgroundColorListChoices);
}
.colorBox .color {
	display: block;
	float: left;
	width: 32px;
	height: 32px;
	margin: 4px;
	padding: 0;
	border-radius: 5px;
	transition: all 0.15s;
	cursor: pointer;
}
.colorBox .color:hover {
	background-color: var(--boxColor);
}
.colorBox .color span {
	display: block;
	width: 14px;
	height: 14px;
	margin: 9px 0 0 9px;
	padding: 0;
	border-radius: 7px;
}
.colorBox .color span.green {
	background-color: #008C4F;
}
.colorBox .color span.lightBlue {
	background-color: #179FD1;
}
.colorBox .color span.red {
	background-color: #D11717;
}
.colorBox .color span.yellow {
	background-color: #D1B217;
}
.colorBox .color span.darkBlue {
	background-color: #1766D1;
}
.colorBox .color span.violet {
	background-color: #9617D1;
}
.colorBox .color span.pink {
	background-color: #D11777;
}
.colorBox .color span.grey {
	background-color: #636363;
}

.clearing {
	display: block;
	clear: both;
	width: 100%;
	margin: 0;
	padding: 0;
}

.absoluteCenter {
	display: flex;
	clear: both;
	margin: 0;
	padding: 0;
}

@keyframes opacityUp {
	from {
		opacity: 0;
		filter: alpha(opacity=0);
	}
	to {
		opacity: 1;
		filter: alpha(opacity=100);
	}
}
@keyframes boxUp {
	from {margin-top: 30px;}
	to   {margin-top: 0;}
}
