.table.setupAccounts .line > .name {
	width: calc(50% - 150px);
	max-width: calc(50% - 150px);
}
.table.setupAccounts .line > .email {
	width: 250px;
	max-width: 250px;
}
.table.setupAccounts .line > .rules {
	width: 20%;
	max-width: 20%;
}
.table.setupAccounts .line > .state {
	width: 250px;
	max-width: 250px;
}
.table.setupAccounts .line > .date {
	width: 150px;
	max-width: 150px;
}