:root {
	--whiteColor: #FFFFFF;
	--whityColor: #F5F5F5;
	--lighterColor: #EBEBEB;
	--lighterColor2: #E6E6E6;
	--lightColor: #D1D1D1;
	--darkColor: #B4B4B4;
	--darkerColor: #A0A0A0;
	--darkestColor: #646464;
	--mainDarkColor: #004B2A;
	--mainColor: #008C4F;
	--mainColorAlpha: #008C4F7F;
	--mainColorAlpha01: #008C4F19;
	
	--fontSizeInputText: 10pt;
	--borderRadiusInputText: 3px;
	--paddingInputText: 0 15px;
	--backgroundColor: var(--whiteColor);
	--backgroundColorBis: var(--lighterColor);
	--backgroundColorBis2: var(--lighterColor2);
	--backgroundColorTer: var(--lightColor);
	--boxColor: var(--whiteColor);
	--borderColor: var(--darkColor);
	
	--logo: url(../image/logo/hivy-logo-black.svg);
	--textColor: black;
	--textColorReverse: white;
	--descriptionColor: rgb(100, 100, 100);
	--errorColor: rgb(195, 48, 48);
	--errorColorAlpha: rgba(195, 48, 48, 0.5);
	--warningColor: rgb(195, 134, 19);
	--warningColorAlpha: rgba(195, 134, 19, 0.5);
	--validColor: var(--mainColor);

	--backgroundColorOptions: var(--backgroundColor);
	--borderColorOptions: 1px solid #D2D2D2;
	--boxShadowOptions: 0 3px 6px rgba(0, 0, 0, 0.2);
	--textColorOptionItems: var(--textColor);
	--backgroundColorOptionItemsHover: var(--lighterColor);
	--textColorOptionItemsRed: rgb(195, 48, 48);
	--backgroundColorOptionItemsRed: rgba(195, 48, 48, 0.1);

	--backgroundColorMenu: var(--backgroundColorBis);
	--borderMenu: 1px solid var(--backgroundColorBis);
	--borderItemMenuSelected: 3px solid var(--mainColor);
	
	--backgroundColorSubMenu: var(--backgroundColorTer);
	--textColorSubMenuNavItem: var(--textColor);
	--backgroundColorSubMenuNavItemHover: var(--backgroundColorBis);

	--backgroundColorUser: var(--mainColor);
	--backgroundColorUserHover: var(--backgroundColorSubmitHover);
	--boxShadowUser: 0 0 1px 1px rgba(0, 0, 0, 0.2);

	--backgroundColorInput: var(--backgroundColorBis);
	--backgroundColorInputHover: var(--lighterColor);
	--textColorInput: black;
	--borderInput: 1px solid var(--backgroundColorTer);
	--borderColorInputHover: #005F35;
	--borderColorInputText: var(--backgroundColorTer);
	--borderColorInputTextFocus: var(--mainColor);
	--backgroundColorNoInputHover:var(--backgroundColor);
	--progressBarColorInputFile: var(--mainColor);
	--backgroundColorTextareaKey: var(--backgroundColorTer);
	--backgroundColorTextareaKeyHover: var(--backgroundColorBis);
	
	--textColorButtonAccess: black;
	--borderButtonAccess: 1px solid var(--mainColorAlpha);
	--borderButtonAccessHover: 1px solid var(--mainColor);
	
	--backgroundColorBarStrengthLow: var(--errorColor);
	--backgroundColorBarStrengthMid: var(--warningColor);
	--backgroundColorBarStrengthStrong: var(--validColor);
	
	--backgroundColorListChoices: var(--backgroundColorInput);
	--backgroundColorListChoicesChoiceHover: var(--backgroundColorInputHover);
	--borderBottomListChoicesChoice: 1px solid var(--boxColor);

	--backgroundColorSubmit: #008C4F;
	--backgroundColorSubmitHover: #005F35;
	--textColorSubmit: white;

	--backgroundColorDelete: #C33030;
	--backgroundColorDeleteHover: #942121;
	--textColorDelete: white;

	--textColorLabel: rgb(100, 100, 100);
	--textColorLabelCheckbox: black;

	--borderTable: 1px solid var(--borderColor);
	--backgroundColorTRHover: var(--mainColorAlpha01);

	--borderTabsBar: 1px solid var(--borderColor);
	--borderTabsBarTab: 2px solid var(--mainColor);
	--borderTabsBarTabTransparent: 2px solid transparent;

	--backgroundColorBubbleInformation: var(--mainColor);
	--textColorBubbleInformation: white;
	
	--backgroundTrLineButton: var(--mainColor);
	--backgroundTrLineButtonHover: var(--backgroundColorSubmitHover);
	
	--backgroundColorInputRichText: rgb(230, 230, 230);
	--textColorInputRichText: rgb(0, 0, 0);
	--borderRadiusInputRichTextWrite: 40px;
	
	--backgroundColorHistoryConversation: #E0E0E0;
}
