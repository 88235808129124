.product {
	display: flex;
	margin: 0;
	padding: 0;
}

.product > .left {
	width: calc(60% - 1px);
	margin: 0;
	padding: 0;
	border-right: var(--borderMenu);
}

.product > .right {
	width: 40%;
	margin: 0;
	padding: 0;
}

.product .prices {
	margin: 0;
	padding: 0;
}
.product .prices .pricesList {
	overflow: hidden;
	margin: 15px;
	padding: 0;
	background-color: var(--mainColor);
	color: var(--textColor);
	text-transform: uppercase;
	border-radius: var(--borderRadiusInputText);
}
.product .prices .pricesList .icons {
	position: relative;
	display: block;
	float: right;
	width: 45px;
	height: 37px;
	margin: 5px -45px 0 0;
	padding: 0;
	transition: all 0.25s;
}
.product .prices .pricesList:hover .icons {
	margin-right: 0;
}
.product .prices .pricesList .icons a {
	border: none;
}
.product .prices .pricesList .icons svg {
	display: block;
	float: left;
	width: 19px;
	height: 19px;
	margin: 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
	fill: white;
}
.product .prices .pricesList .icons svg:hover {
	fill: black;
}
.product .prices .pricesList .icons svg.bounce {
	animation: bounce 1s ease;
}
.product .prices .pricesList .name {
	float: left;
	width: calc(100% - 80px);
	font-weight: bold;
	font-size: 10pt;
	margin: 0;
	padding: 15px 15px 20px 15px;
}
.product .prices .pricesList .headers {
	clear: both;
	display: flex;
	padding: 0 0 15px 0;
}
.product .prices .pricesList .headers .head {
	width: calc(100% / 3);
	margin: 0;
	padding: 0;
	text-align: center;
	text-transform: uppercase;
	font-size: 9pt;
	font-weight: bold;
}
.product .prices .pricesList .keys {
	clear: both;
	display: flex;
}
.product .prices .pricesList .keys .key {
	width: calc(100% / 3);
	margin: 0;
	padding: 0 0 5px 0;
	text-align: center;
	font-size: 20pt;
	font-weight: bold;
}

.product .stocks {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0 15px 15px 15px;
}
.product .stocks .storageStock {
	flex: 1;
	overflow: hidden;
	margin: 0 0 15px 0;
	padding: 0 15px;
	background-color: var(--mainColor);
	color: var(--textColor);
	text-transform: uppercase;
	border-radius: var(--borderRadiusInputText);
}
.product .stocks .storageStock:nth-child(odd) {
	margin-right: 7px;
}
.product .stocks .storageStock:nth-child(even) {
	margin-left: 7px;
}
.product .stocks .storageStock.empty {
	background-color: var(--errorColor);
}
.product .stocks .storageStock.warning {
	background-color: var(--warningColor);
}
.product .stocks .storageStock.ok {
	background-color: var(--validColor);
}
.product .stocks .storageStock .title {
	clear: both;
	padding: 10px 0 0 0;
	text-align: center;
	text-transform: uppercase;
	font-size: 9pt;
	font-weight: bold;
}
.product .stocks .storageStock .key {
	float: left;
	width: calc(100% - 74px);
	margin: 0;
	padding: 10px 0 5px 0;
	text-align: center;
	font-size: 26pt;
	font-weight: bold;
}
.product .stocks .storageStock .less {
	position: relative;
	display: block;
	float: left;
	width: 37px;
	height: 37px;
	margin: 10px 0 0 0;
	padding: 0;
	transition: all 0.25s;
}
.product .stocks .storageStock:hover .less {
	margin-left: 0;
}
.product .stocks .storageStock .less a {
	border: none;
}
.product .stocks .storageStock .less svg {
	display: block;
	float: left;
	width: 19px;
	height: 19px;
	margin: 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
	fill: white;
}
.product .stocks .storageStock .less svg:hover {
	fill: black;
}
.product .stocks .storageStock .less svg.bounce {
	animation: bounce 1s ease;
}
.product .stocks .storageStock .plus {
	position: relative;
	display: block;
	float: right;
	width: 37px;
	height: 37px;
	margin: 10px 0 0 0;
	padding: 0;
	transition: all 0.25s;
}
.product .stocks .storageStock:hover .plus {
	margin-right: 0;
}
.product .stocks .storageStock .plus a {
	border: none;
}
.product .stocks .storageStock .plus svg {
	display: block;
	float: left;
	width: 19px;
	height: 19px;
	margin: 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
	fill: white;
}
.product .stocks .storageStock .plus svg:hover {
	fill: black;
}
.product .stocks .storageStock .plus svg.bounce {
	animation: bounce 1s ease;
}
