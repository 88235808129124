.cardInformations {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 167px);
    max-height: calc(100vh - 167px);
}

.settingsMenu {
	display: block;
}

.cardInformations > .contForm {
	display: block;
	float: left;
	width: calc(60% - 1px);
	height: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	border-right: var(--borderMenu);
}

.cardInformations > .contPhases {
	display: block;
	float: left;
	width: 40%;
	margin: 0;
	padding: 0;
}

.cardInformations > .contContracts {
	display: block;
	float: left;
	width: 40%;
	margin: 0;
	padding: 0;
}
