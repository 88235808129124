.prospectPhaseInProgress {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	min-height: 100%;
	margin: 0;
	padding: 15px;
}

.prospectPhaseInProgress > .phase {
	display: flex;
	flex-flow: row;
	width: 100%;
	margin: 0 0 15px 0;
	padding: 10px 15px;
	border-radius: 5px;
	color: var(--textColor);
	cursor: pointer;
	transition: all 0.15s;
}
.prospectPhaseInProgress > .phase:hover {
	filter: brightness(90%);
}

.prospectPhaseInProgress > .phase > .description {
	width: 50%;
	margin: 0;
	padding: 0;
}

.prospectPhaseInProgress > .phase > .description > .name {
	margin: 0;
	padding: 0;
	font-size: 11pt;
	text-align: left;
	text-transform: uppercase;
}

.prospectPhaseInProgress > .phase > .dates {
	width: 50%;
	margin: 0;
	padding: 2px 0 0 0;
}

.prospectPhaseInProgress > .phase > .dates > .date {
	margin: 0;
	padding: 0;
	font-size: 9pt;
	text-align: right;
}
.prospectPhaseInProgress > .phase > .dates > .timer {
	margin: 0;
	padding: 0;
	font-size: 9pt;
	text-align: right;
}
