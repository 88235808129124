.tabs {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: var(--borderTabsBar);
	background-color: var(--whityColor);
}

.tabs > .title {
    float: left;
    width: 50%;
    margin: 15px 15px 5px 15px;
    padding: 0;
    font-size: 10pt;
	line-height: 37px;
    color: var(--textColor);
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
}
.tabs > .title > .vignette {
	display: inline;
    margin: -2px 0 0 15px;
	padding: 8px 12px;
	font-size: 8pt;
	font-weight: bold;
	border-radius: 5px;
	cursor: pointer;
	transition: all .15s;
}
.tabs > .title > .vignette.ACTIVE {
	background-color: var(--mainColor);
}
.tabs > .title > .vignette.ACTIVE:hover {
	filter: brightness(90%);
}
.tabs > .title > .vignette.STANDBY {
	background-color: var(--warningColor);
}
.tabs > .title > .vignette.STANDBY:hover {
	filter: brightness(90%);
}
.tabs > .title > .vignette.EJECT {
    background-color: var(--errorColor);
}
.tabs > .title > .vignette.EJECT:hover {
	filter: brightness(90%);
}

.tabs .button {
    display: block;
    float: right;
    margin: 15px 15px 0 0;
    padding: 10px 15px;
    font-size: 8pt;
    font-weight: bold;
    text-transform: uppercase;
    background-color: var(--backgroundColorSubmit);
	background-image: url("../../../image/ressource/cross-white.svg");
	background-position: left -20px center;
	background-size: 16px 16px;
	background-repeat: no-repeat;
    color: var(--textColorSubmit);
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.15s;
}
.tabs .button:hover {
	padding-left: 50px;
	background-position-x: 15px;
    background-color: var(--backgroundColorSubmitHover);
}

.tabs nav {
	display: block;
    clear: both;
	float: left;
    width: calc(100% - 50px);
    margin: 15px 0 0 0;
    padding: 0;
}
.tabs nav .tab {
    display: block;
    float: left;
    margin: 0;
    padding: 10px 20px;
    color: var(--textColor);
    font-size: 9pt;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: var(--borderTabsBarTabTransparent);
    transition: all 0.15s;
}
.tabs nav .tab.selected {
    border-bottom: var(--borderTabsBarTab);
}
.tabs nav .tab:hover {
    border-bottom: var(--borderTabsBarTab);
}

.tabs .access {
    float: right;
    width: 24px;
    height: 24px;
    margin: 20px 12px 0 0;
    padding: 0;
    cursor: pointer;
    transition: all 0.15s;
}
.tabs .access svg {
    transition: all 0.15s;
}
.tabs .access:hover svg {
    fill: var(--mainColor);
}
