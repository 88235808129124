.react-calendar {
	width: 350px;
	max-width: 100%;
	background: var(--boxColor);
	border: 1px solid var(--backgroundColor);
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
	color: var(--textColor);
}
.react-calendar--doubleView {
	width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}
.react-calendar button:enabled:hover {
	cursor: pointer;
}
.react-calendar__navigation {
	height: 44px;
	margin-bottom: 1em;
}
.react-calendar__navigation button {
	min-width: 44px;
	background: none;
	font-weight: bold;
	color: var(--textColor);
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: var(--backgroundColorInputHover);
}
.react-calendar__navigation button[disabled] {
	background-color: var(--backgroundColorInputHover);
}
.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
	font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day {
	color: var(--textColor);
}
.react-calendar__month-view__days__day--weekend {
	color: #d10000;
	font-weight: bold;
}
.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
}
.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year,
.react-calendar__century-view__decades__decade {
	font-weight: bold;
	color: var(--textColor);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}
.react-calendar__tile {
	max-width: 100%;
	text-align: center;
	padding: 0.75em 0.5em;
	background: none;
}
.react-calendar__tile:disabled {
	background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: var(--backgroundColorInputHover);
}
.react-calendar__tile--now {
	background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #ffffa9;
}
.react-calendar__tile--hasActive {
	background: var(--mainColor);
	color: white;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: var(--mainColor);
}
.react-calendar__tile--active {
	background: var(--mainColor);
	color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: var(--mainColor);
}
.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: var(--backgroundColorInputHover);
}
