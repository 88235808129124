.table.setupPhase .line > .name {
	width: calc(100% - 60px);
	max-width: calc(100% - 60px);
}

.table.setupPhase .line > .action {
	width: 30px;
}

.table.setupPhase .line:hover > .action .access {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}
.table.setupPhase .line > .action .access {
	width: 30px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
	transition: all 0.15s;
}
.table.setupPhase .line > .access svg {
	width: 30px;
	transition: all 0.15s;
}
.table.setupPhase .line > .access:hover svg {
	fill: var(--mainColor);
}
