.toolsBarComponent {
	padding: 15px 15px 0 15px;
	background-color: var(--whityColor);
}

.toolsBarComponent .button {
	display: block;
	float: right;
	margin: 0;
	padding: 10px 15px;
	font-size: 8pt;
	font-weight: bold;
	text-transform: uppercase;
	background-color: var(--backgroundColorSubmit);
	color: var(--textColorSubmit);
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.15s;
}
.toolsBarComponent .button:hover {
	background-color: var(--backgroundColorSubmitHover);
}
