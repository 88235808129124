.clientSetup {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 167px);
    max-height: calc(100vh - 167px);
}

.absoluteCenter {
	height: 100%;
}
.clientSetup .emptyBox {
	width: 120px;
	height: 120px;
	margin: auto;
}
