.subMenuNavItem {
	width: calc(100% - 30px);
	max-width: calc(100% - 30px);
	margin: 5px 15px;
	padding: 12px 15px;
	font-size: 10pt;
	text-align: left;
	text-transform: uppercase;
	border-radius: 3px;
	color: var(--textColorSubMenuNavItem);
	background-color: var(--lightColor);
	border-left: 2px solid var(--lighterColor);
	cursor: pointer;
	transition: all 0.15s;
}
.subMenuNavItem.selected {
	background-color: var(--mainColor);
	border-left: 2px solid var(--mainColor);
	color: white;
}
.subMenuNavItem:not(.selected):hover {
	background-color: var(--darkColor);
	border-left: 2px solid var(--mainColor);
}
