.table.provider .line > .name {
	width: 40%;
	max-width: 40%;
}

.table.provider .line > .reference {
	width: 30%;
	max-width: 30%;
}

.table.provider .line > .partnership {
	width: calc(30% - 50px);
	max-width: calc(30% - 50px);
}
