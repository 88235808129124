.subMenu {
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 11;
	max-width: 280px;
	width: 0;
	height: 100vh;
	min-height: 100vh;
	max-height: 100vh;
	left: 65px;
	top: 0;
	margin: 0;
	padding: 0;
	background: linear-gradient(to right, var(--lighterColor) 75%, var(--lighterColor2));
	border-right: none;
	transition: all 0.15s;
}
.subMenu.show {
	width: 280px;
	border-right: 2px solid var(--darkColor);
}

.subMenu .title {
	margin: 0;
	padding: 0 15px 6px 15px;
	font-size: 11pt;
	line-height: 60px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: left;
	color: var(--textColor);
}

.subMenu nav a {
	text-decoration: none;
	color: inherit;
}
