.table.product .line > .name {
	width: 30%;
	max-width: 30%;
}

.table.product .line > .reference {
	width: 15%;
	max-width: 15%;
}

.table.product .line > .category {
	width: 20%;
	max-width: 20%;
}

.table.product .line > .brand {
	width: 20%;
	max-width: 20%;
}

.table.product .line > .partnership {
	width: calc(15% - 50px);
	max-width: calc(15% - 50px);
}
