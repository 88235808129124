.contForm {
	display: block;
	margin: 0;
	padding: 0;
}
.form {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 15px;
}
.form.margin {
	max-height: 700px;
	margin: 5px 20px;
}
.form.noPadding {
	padding: 0;
}
.form.tabBar {
	float: right;
	width: 250px;
	margin: 0 15px 0 0;
	padding: 0;
}
.form.noOverflow {
	 overflow: visible;
}

.form .titleForm {
	overflow: hidden;
	display: block;
	margin: 15px 20px 20px 20px;
	padding: 0 20px;
	font-size: 11pt;
	line-height: 24px;
	font-weight: bold;
	text-transform: uppercase;
	color: var(--textColorInput);
}
.form .titleForm.noMarginTop {
	margin-top: 0;
}
.form .titleForm.center {
	text-align: center;
}

.form .globalError {
	margin: 0 0 15px 0;
	padding: 10px 10px;
	font-size: 10pt;
	line-height: 20px;
	text-align: left;
	border-radius: 3px;
	color: rgba(255, 255, 255, 1);
	background-color: rgb(195, 48, 48);
}
.form .globalValid {
	margin: 0 0 15px 0;
	padding: 10px 10px;
	font-size: 10pt;
	line-height: 20px;
	text-align: left;
	border-radius: 3px;
	color: rgba(255, 255, 255, 1);
	background-color: var(--validColor);
}

.form .leftPart {
	display: block;
	float: left;
	width: 50%;
	min-height: 100%;
	height: 100%;
	margin: 0;
	padding: 0 15px 0 0;
	border-right: var(--borderMenu);
}
.form .leftPart.border {
	border-right: var(--borderMenu);
}
.form .rightPart {
	display: block;
	float: right;
	width: calc(50% - 1px);
	min-height: 100%;
	height: 100%;
	margin: 0;
	padding: 0 0 0 15px;
}
.form .rightPart.border {
	border-left: var(--borderMenu);
}

.form .separator {
	display: block;
	width: 100%;
	margin: 20px 0 5px 0;
	padding: 0;
	border-top: var(--borderMenu);
}

.form .preview {
	width: calc(100% - 30px);
	height: 39px;
	margin: 5px 0 0 0;
	padding: 0 15px;
	color: rgb(70, 70, 70);
	line-height: 37px;
	text-align: center;
	cursor: pointer;
	transition: all 0.15s;
}
.form .preview.empty {
	color: rgb(150, 150, 150);
}
.form .preview.high {
	font-size: 13pt;
}
.form .preview.normal {
	font-size: 11pt;
}
.form .preview.left {
	text-align: left;
}
.form .preview.right {
	text-align: right;
}
.form .preview:hover {
	color: rgb(30, 30, 30);
	background-color: rgb(245, 245, 245);
}
.form .preview .edit {
	opacity: 0;
	filter: alpha(opacity=0);
	width: 16px;
	height: 16px;
	margin: 11px 0 0 15px;
	padding: 0;
}
.form .preview .edit.right {
	float: right;
}
.form .preview:hover .edit {
	opacity: 1;
	filter: alpha(opacity=100);
}

.form .preview.postalCode {
	float: left;
	width: calc(40% - 30px);
}
.form .preview.city {
	float: right;
	width: calc(60% - 40px);
}

.form .label {
	display: block;
	height: 18px;
	margin: 15px 0 0 0;
	padding: 0 0 0 15px;
	font-size: 10pt;
	text-align: left;
	color: var(--textColorLabel);
}
.form .label.left {
	float: left;
	width: 50%;
}
.form .label.right {
	float: right;
	width: calc(50% - 10px);
}
.form .label.clear {
	clear: both;
}
.form .label.postalCode {
	float: left;
	width: calc(40% - 15px);
}
.form .label.city {
	float: right;
	width: calc(60% - 25px);
}

.form .noInput {
	overflow: hidden;
	display: block;
	width: 100%;
	min-height: 39px;
	margin: 5px 0 0 0;
	padding: var(--paddingInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	transition: all 0.25s;
}
.form .noInput.left {
	float: left;
	width: calc(50% - 10px);
}
.form .noInput.right {
	float: right;
	width: calc(50% - 10px);
}
.form .noInput.clear {
	clear: both;
}
.form .noInput.titleConversation {
	padding: 10px 15px;
	margin-bottom: 10px;
}
.form .noInput:hover {
	background-color: var(--backgroundColorNoInputHover);
}
.form .noInput .textRichInput {
    overflow-x: hidden;
    overflow-y: auto;
	display: block;
	float: left;
	width: calc(100% - 111px);
	max-height: 300px;
	margin: 0;
	padding: 15px 0;
	line-height: 1em;
	color: var(--textColorInput);
}
.form .noInput .textRichInput.descriptionConversation {
	font-size: 1.1em;
}
.form .noInput .textInput {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	display: block;
	float: left;
	width: calc(100% - 111px);
	margin: 0;
	padding: 0;
	line-height: 39px;
	font-size: 10pt;
	font-weight: bold;
	color: var(--textColorInput);
}
.form .noInput.titleConversation .textInput {
	font-size: 18pt;
}
.form .noInput.descriptionConversation .textInput {
	font-size: 12pt;
}
.form .noInput .icons {
	position: relative;
	display: block;
	float: right;
	width: 111px;
	height: 37px;
	margin: 0 -126px 0 0;
	padding: 0;
	transition: all 0.25s;
}
.form .noInput:hover .icons {
	margin-right: 0;
}
.form .noInput .icons a {
	border: none;
}
.form .noInput .icons svg {
	display: block;
	float: right;
	width: 37px;
	height: 37px;
	margin: 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
}
.form .noInput .icons svg:hover, .form .inputText svg:hover {
	fill: var(--mainColor);
}
.form .noInput .icons svg.bounce {
	animation: bounce 1s ease;
}

.form .noInputComplex {
	overflow: hidden;
	display: block;
	width: 100%;
	margin: 5px 0 0 0;
	padding: var(--paddingInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	transition: all 0.25s;
}
.form .noInputComplex.left {
	float: left;
	width: calc(50% - 40px);
}
.form .noInputComplex.right {
	float: right;
	width: calc(50% - 40px);
}
.form .noInputComplex.clear {
	clear: both;
}
.form .noInputComplex:hover {
	background-color: var(--backgroundColorNoInputHover);
}
.form .noInputComplex .textInput {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	display: block;
	float: left;
	width: calc(100% - 151px);
	margin: 0;
	padding: 0;
	line-height: 39px;
	font-size: 10pt;
	font-weight: bold;
	color: var(--textColorInput);
}
.form .noInputComplex .icons {
	position: relative;
	display: block;
	float: right;
	width: 151px;
	height: 37px;
	margin: 0 -166px 0 0;
	padding: 0;
	transition: all 0.25s;
}
.form .noInputComplex:hover .icons {
	margin-right: 0;
}
.form .noInputComplex .icons a {
	border: none;
}
.form .noInputComplex .icons svg {
	display: block;
	float: right;
	width: 37px;
	height: 37px;
	margin: 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
}
.form .noInputComplex .icons svg:hover, .form .inputText svg:hover {
	fill: var(--mainColor);
}
.form .noInputComplex .icons svg.bounce {
	animation: bounce 1s ease;
}
.form .noInputComplex .contTags {
	display: block;
	clear: both;
	width: 100%;
	margin: 0;
	padding: 0 0 10px 0;
}
.form .noInputComplex .contTags .tag {
	display: block;
	float: left;
	margin: 0 5px 0 0;
	padding: 5px 8px;
	border-radius: 3px;
	font-size: 10pt;
	font-weight: bold;
	background-color: var(--mainColor);
}
.form .noInputComplex .contTags .tag.skytillAccount {
	background-color: rgb(27, 83, 115);
}

.form .inputText {
	display: block;
	width: 100%;
	height: 39px;
	margin: 5px 0 0 0;
	padding: 0;
}
.form.tabBar .inputText {
	height: 30px;
	margin: 0;
}
.form .inputText.alone {
	width: auto;
	float: left;
	margin-right: 10px;
}
.form .inputText.left {
	float: left;
	width: calc(50% - 10px);
}
.form .inputText.right {
	float: right;
	width: calc(50% - 10px);
}
.form .inputText.clear {
	clear: both;
}
.form .inputText.inline {
	display: block;
	float: left;
	width: auto;
	max-width: calc((100% / 3) - 10px);
	margin: 10px 10px 0 0;
}
.form .inputText input[type="text"],
.form .inputText input[type="password"] {
	float: left;
	width: calc(100% - 37px);
	margin-top: 0;
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.form .inputText input[type="text"]:hover,
.form .inputText input[type="password"]:hover {
	border-color: var(--mainColor);
}
.form .inputText input[type="text"]:focus,
.form .inputText input[type="password"]:focus {
	border-color: var(--mainDarkColor);
}
.form .inputText .customDatePicker {
	float: left;
	width: 100%;
	height: 37px;
	margin-top: 0;
	padding-right: 0;
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.form .inputText .customDatePicker.ant-picker-focused {
	outline: none;
	border-color: var(--borderColorInputHover);
	background-color: var(--backgroundColorInputHover);
	box-shadow: none;
}
.form .inputText .customDatePicker:hover {
	background-color: var(--backgroundColorInputHover);
}
.form .inputText .customDatePicker input {
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	background-color: transparent;
}
.form .inputText .customDatePicker .ant-picker-suffix {
	display: block;
	float: right;
	margin: 0 4px 0 0;
}
.form .inputText .customDatePicker svg {
	fill: var(--mainColor);
}
.form .inputText input[type="text"].extend,
.form .inputText input[type="password"].extend,
.form .inputText .customDatePicker.extend {
	width: 100%;
}
.form .inputText select {
	float: left;
	width: calc(100% - 37px);
    padding-right: 45px;
	margin-top: 0;
	cursor: pointer;
    background-position: right 15px center;
    background-size: 14px 14px;
    background-repeat:  no-repeat;
    background-image: url(../../image/form/down-arrow.svg);
}
.form .inputText select.extend {
	float: left;
	width: 100%;
    padding-right: 45px;
	margin-top: 0;
    background-position: right 15px center;
    background-size: 14px 14px;
    background-repeat:  no-repeat;
    background-image: url(../../image/form/down-arrow.svg);
}
.form .inputText svg {
	display: block;
	float: right;
	width: 19px;
	height: 19px;
	margin: 10px 9px;
	padding: 0;
	cursor: pointer;
	transition: all 0.15s;
}
.form .inputText .searchInput {
	display: block;
	float: right;
	margin-top: -28px;
	margin-right: 15px;
}

.form .inputTextarea {
	position: relative;
	display: block;
	width: 100%;
	margin: 5px 0 0 0;
	padding: 0;
}
.form .inputTextarea.left {
	float: left;
	width: calc(50% - 10px);
}
.form .inputTextarea.right {
	float: right;
	width: calc(50% - 10px);
}
.form .inputTextarea.clear {
	width: calc(100% - 30px);
	clear: both;
}
.form .inputTextarea.writeComment {
	overflow: hidden;
	width: calc(100% - 30px);
	margin: 15px;
	border-radius: var(--borderRadiusInputRichTextWrite);
}
.form .inputTextarea > .quill {
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--backgroundColorInputRichText);
	border-radius: 3px;
	margin: 0;
	padding: 15px 170px 15px 15px;
	min-height: 200px;
	max-height: 300px;
}
.form .inputTextarea.writeComment > .quill {
	min-height: 50px;
}
.form .inputTextarea > .quill .ql-container {
	color: var(--textColorInputRichText);
	font-size: 11pt;
 }
.form .inputTextarea > .validate {
	display: block;
	position: absolute;
	float: right;
	right: 25px;
	bottom: 15px;
	height: 40px;
	margin: 0;
	padding: 0 23px;
	border-radius: 20px;
	font-size: 11pt;
	font-weight: bold;
	line-height: 40px;
	text-transform: uppercase;
	background-color: white;
	color: rgb(100, 100, 100);
	box-shadow: 0 0 5px rgba(0, 0, 0, .3);
	cursor: pointer;
	transition: all 0.15s;
}
.form .inputTextarea > .validate:hover {
	background-color: var(--mainColor);
	color: white;
}

.form .inputRadio {
	display: block;
	width: 100%;
	height: 39px;
	margin: 5px 0 0 0;
	padding: 0;
	cursor: pointer;
	transition: all 0.15s;
}
.form .inputRadio:hover {
	background-color: var(--backgroundColorInput);
}
.form .inputRadio.selected {
	background-color: var(--backgroundColorInputHover);
}
.form .inputRadio .puce {
	display: block;
	float: left;
	margin: 14px 20px 0 20px;
	padding: 5px;
	border-radius: 10px;
	background-color: var(--textColor);
	transition: all 0.15s;
}
.form .inputRadio.selected .puce {
	background-color: var(--mainColor);
}
.form .inputRadio .textRadio {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	float: left;
	width: calc(100% - 80px);
	margin: 0;
	padding: 0;
	line-height: 39px;
	font-size: 10pt;
	font-weight: bold;
	color: var(--textColorInput);
}
.form .inputRadio .input {
	width: calc(100% - 80px);
	margin: 0;
	padding: 0;
	line-height: 39px;
	color: var(--textColorInput);
	font-size: 10pt;
	font-weight: bold;
	border: none;
	background-color: transparent;
}
.form .inputRadio .input:hover {
	background-color: transparent;
}

.form .inputColor {
	display: block;
	width: 100%;
	height: 39px;
	margin: 5px 0 0 0;
	padding: 0;
}
.form .inputColor.marginBottom {
	margin-bottom: 10px;
}
.form .inputColor.left {
	float: left;
	width: calc(50% - 10px);
}
.form .inputColor.right {
	float: right;
	width: calc(50% - 10px);
}
.form .inputColor.clear {
	clear: both;
}
.form .inputColor .color {
	display: block;
	float: left;
	position: relative;
	width: calc(100% - 54px);
	height: 37px;
	margin: 5px 0 0 0;
	padding: 0 37px 0 15px;
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	line-height: 37px;
	text-transform: uppercase;
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	cursor: pointer;
}
.form .inputColor.edit .color {
	width: calc(100% - 101px);
}
.form .inputColor .color:hover {
	background-color: var(--backgroundColorInputHover);
}
.form .inputColor .color.select {
	outline: none;
	border-color: var(--borderColorInputHover);
	background-color: var(--backgroundColorInputHover);
}
.form .inputColor .color.wrong {
	border-color: rgb(195, 48, 48);
}
.form .inputColor .puce {
	display: block;
	overflow: hidden;
	position: absolute;
	right: 15px;
	top: 8px;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
	border-radius: 10px;
	box-shadow: 0 0 1px rgb(100, 100, 100);
}
.form .inputColor > .popover {
	position: absolute;
	z-index: 2;
}
.form .inputColor > .popover > .cover {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.form .inputColor svg {
	display: none;
	float: right;
	width: 19px;
	height: 19px;
	margin: 8px 0 0 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
}
.form .inputColor.edit svg {
	display: block;
}

.form .contInputChoices {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
}
.form .inputChoices {
    display: block;
    float: left;
    width: calc(100% - 39px);
    max-width: calc(100% - 39px);
	min-height: 42px;
    margin: 5px 0 0 0;
    padding: 0 0 5px 0;
    border-radius: var(--borderRadiusInputText);
    border: var(--borderInput);
    background-color: var(--backgroundColorInput);
}
.form .inputChoices.extend {
    width: 100%;
    max-width: 100%;
}
.form .inputChoices:hover {
    background-color: var(--backgroundColorInputHover);
}
.form .inputChoices input[type="text"] {
    float: left;
    margin: 0;
    line-height: 39px;
    border-radius: 0;
    border: none;
    background-color: transparent;
}
.form .inputChoices input[type="text"]:hover {
    background-color: transparent;
}
.form .inputChoices input:focus {
    outline: none;
    border-color: transparent;
    background-color: transparent;
}
.form .inputChoices svg {
    float: left;
    width: 20px;
    height: 20px;
    margin: 10px 10px 5px 10px;
    padding: 0;
    cursor: pointer;
    transition: all 0.15s;
}
.form .inputChoices svg:hover {
    fill: var(--mainColor);
}
.form .inputChoices .choice {
	display: block;
	float: left;
	margin: 5px 0 0 5px;
	padding: 0 8px 0 8px;
	border-radius: 3px;
	background-color: var(--mainColor);
}
.form .inputChoices .choice p {
	display: block;
	float: left;
	margin: 0;
	padding: 0;
	font-size: 10pt;
	line-height: 30px;
	font-weight: bold;
	color: white;
}
.form .inputChoices .choice .contDelete {
	float: right;
	margin: 9px 0 0 15px;
	padding: 0;
}
.form .inputChoices .choice svg {
	margin: 0;
	padding: 0;
	width: 12px;
	height: 12px;
	transition: all 0.2s;
}
.form .inputChoices .choice svg:hover {
	fill: white;
	transform: rotate(90deg);
}
.form .contInputChoices .checkIcon {
    display: block;
    float: right;
    width: 19px;
    height: 19px;
    margin: 0;
    padding: 15px 9px 0 0;
    cursor: pointer;
    transition: all 0.15s;
}
.form .contInputChoices .checkIcon:hover {
    fill: var(--mainColor);
}

.form .inputTag {
	display: flex;
	width: calc(100% - 2px);
	min-height: 37px;
	margin: 5px 0 0 0;
	padding: 0;
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.form .inputTag > .containerTag {
	display: block;
	height: 100%;
	margin: 0;
	padding: 0 0 5px 5px;
}
.form .inputTag > .containerTag.buttonWithTitle {
	width: calc(100% - 75px);
}
.form .inputTag > .containerTag.buttonSvg {
	width: calc(100% - 25px);
}
.form .inputTag > .containerTag > .tag {
	display: block;
	float: left;
	margin: 5px 5px 0 0;
	padding: 0 10px;
	border-radius: 3px;
	background-color: var(--mainDarkColor);
}
.form .inputTag > .containerTag > .tag > p {
	display: block;
	float: left;
	margin: 0;
	padding: 0;
	font-size: 9pt;
	line-height: 25px;
	color: var(--whiteColor);
}
.form .inputTag > .containerTag > .tag > svg {
	float: left;
	width: 10px;
	height: 10px;
	margin: 8px 0 0 15px;
	padding: 0;
	fill: var(--whiteColor);
	cursor: pointer;
	transition: all .15s;
}
.form .inputTag > .containerTag > .tag > svg:hover {
	transform: rotate(90deg);
}
.form .inputTag > .containerTag input[type="text"] {
	display: block;
	float: left;
	width: 100px;
	height: 27px;
	margin: 0;
	background-color: transparent;
	border-radius: 0;
	border: none;
	font-size: 9pt;
	text-align: left;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.form .inputTag > .button {
	display: block;
	width: 70px;
	height: 25px;
	margin: 5px 5px 0 0;
	padding: 0;
	font-size: 9pt;
	line-height: 25px;
	text-align: center;
	background-color: var(--darkestColor);
	color: var(--whiteColor);
	border-radius: 3px;
	transition: all .15s;
	cursor: pointer;
}
.form .inputTag > svg {
	display: block;
	width: 20px;
	height: 20px;
	margin: 7px 5px 0 0;
	padding: 0;
	fill: var(--darkestColor);
	transition: all .15s;
	cursor: pointer;
}
.form .inputTag > .button:hover {
	background-color: var(--darkerColor);
}
.form .inputTag > svg:hover {
	fill: var(--darkerColor);
}
.form .listTag {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.form .listTag > li.empty {
	font-size: 10pt;
	color: var(--darkerColor);
}
.form .listTag > li.choice {
	font-size: 10pt;
}

.form .inputSwitch {
	height: 30px;
	margin: 0;
}
.form .inputSwitch.marginLR {
	margin-left: 10px;
	margin-right: 10px;
}
.form .inputSwitch.marginTop {
	margin-top: 30px;
}
.form .inputSwitch.marginBottom {
	margin-bottom: 30px;
}
.form .inputSwitch.marginTB {
	margin-top: 0;
	margin-bottom: 20px;
}
.form .inputSwitch > label {
	display: block;
	width: 100%;
	margin: 5px 0 0 0;
	padding: 0;
	cursor: pointer;
}
.form .inputSwitch.inline > label {
	margin: 0 auto;
}
.form .inputSwitch > label > span {
	display: block;
	float: left;
	width: calc(75% - 10px);
	margin: 0;
	padding: 5px 0 0 10px;
	font-size: 9pt;
	text-align: left;
	line-height: 20px;
	color: rgb(100, 100, 100);
}
.form .inputSwitch.inline > label > span {
	display: none;
}
.form .inputSwitch > label > .react-switch {
	float: right;
	margin: 4px 15px 0 0;
	padding: 0;
}
.form .inputSwitch.inline > label > .react-switch {
	margin: 4px 30px;
	padding: 0;
}

.form .inputFile {
	position: relative;
	overflow: hidden;
	display: block;
	width: 100%;
	height: 200px;
	margin: 0 0 30px 0;
	padding: 0;
	border-radius: var(--borderRadiusInputText);
	border: 1px solid rgb(130, 130, 130);
	cursor: pointer;
	transition: all 0.15s;
}
.form .inputFile.logo {
	width: 120px;
	height: 120px;
	margin-left: auto;
	margin-right: auto;
	background-position: center center;
	background-size: 40px 40px;
	background-repeat: no-repeat;
}
.form .inputFile.logo.white {
	background-image: url(../../image/ressource/bg-upload-picture-white.svg);
}
.form .inputFile.logo.black {
	background-image: url(../../image/ressource/bg-upload-picture-black.svg);
}
.form .inputFile.extend {
	width: 100%;
	max-width: 100%;
}
.form .inputFile:hover {
	background-color: var(--backgroundColorInputHover);
}
.form .inputFile input[type="file"] {
	display: none;
}
.form .inputFile .img {
	display: block;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	border: none;
}
.form .inputFile .progressBar {
	position: absolute;
	width: 0;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: var(--progressBarColorInputFile);
}

.form .inputMultiLinesChoices {
    overflow-x: hidden;
    overflow-y: auto;
	width: calc(100% - 2px);
    height: 200px;
    max-height: 200px;
	margin: 15px 0 0 0;
	padding: 0;
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.form .inputMultiLinesChoices.minimalHeight {
    height: 120px;
    max-height: 120px;
}
.form .inputMultiLinesChoices.noMarginTop {
    margin-top: 5px;
}
.form .inputMultiLinesChoices .choiceMultiLine {
	display: block;
	clear: both;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0;
	transition: all 0.15s;
	border-bottom: var(--borderBottomListChoicesChoice);
	cursor: pointer;
}
.form .inputMultiLinesChoices .choiceMultiLine:last-child {
	border-bottom: none;
}
.form .inputMultiLinesChoices .choiceMultiLine:hover {
	background-color: var(--backgroundColorInputHover);
}
.form .inputMultiLinesChoices .choiceMultiLine .contChecboxChoice {
    float: left;
	overflow: hidden;
    width: 40px;
	height: 50px;
    margin: 0;
    padding: 0;
}
.form .inputMultiLinesChoices .choiceMultiLine .contChecboxChoice > .loader {
    width: 18px;
	height: 18px;
	margin: 16px 0 0 10px;
    padding: 0;
	transition: all 0.10s;
}
.form .inputMultiLinesChoices .choiceMultiLine .contChecboxChoice > .checkboxChoice {
    width: 18px;
	height: 18px;
	margin: 16px 0 0 -20px;
    padding: 0;
	fill: var(--mainColor);
	transition: all 0.10s;
}
.form .inputMultiLinesChoices .choiceMultiLine .contChecboxChoice > .checkboxChoice.selected {
    margin-left: 10px;
}
.form .inputMultiLinesChoices .choiceMultiLine .contTextChoice {
	float: left;
	width: calc(100% - 40px);
	height: 50px;
    margin: 0;
    padding: 0;
}
.form .inputMultiLinesChoices .choiceMultiLine .contTextChoice .main {
	display: block;
    width: 100%;
    margin: 0;
    padding: 7px 0 0 0;
	text-align: left;
	font-size: 10pt;
	line-height: 20px;
	color: var(--textColor);
}
.form .inputMultiLinesChoices .choiceMultiLine .contTextChoice .main.middle {
    padding: 0;
	line-height: 50px;
}
.form .inputMultiLinesChoices .choiceMultiLine .contTextChoice .secondary {
    width: 100%;
    margin: 0;
    padding: 0;
	text-align: left;
	font-size: 8pt;
	color: var(--textColor);
}

.form input, .form select {
	transition: all 0.15s;
}
.form input.center, .form select.center {
	text-align: center;
}
.form input.left, .form select.left {
	text-align: left;
}
.form input.right, .form select.right {
	text-align: right;
}
.form input[type="text"],
.form input[type="password"],
.formLogin input[type="password"],
.form select,
.form textarea {
	display: block;
	width: calc(100% - 30px);
	height: 37px;
	margin: 5px 0 0 0;
	padding: var(--paddingInputText);
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.form.tabBar select {
	height: 30px;
	margin: 0;
}
.form input[type="text"]:hover,
.form input[type="password"]:hover,
.formLogin input[type="password"]:hover,
.form select:hover,
.form textarea:hover {
	background-color: var(--backgroundColorInputHover);
}
.form input[type="checkbox"] {
	margin: 0 10px 0 0;
}
.form input:focus, .form select:focus, .form textarea:focus {
	outline: none;
	border-color: var(--borderColorInputHover);
	background-color: var(--backgroundColorInputHover);
}
.form input.wrong, form select.wrong, form textarea.wrong {
	border-color: rgb(195, 48, 48);
}

.form .error, .form .error, .form .error {
	margin: 0;
	padding: 5px 10px 0 10px;
	font-size: 9pt;
	text-align: left;
	color: rgb(195, 48, 48);
}
.form .error.left, .form .error.left, .form .error.left {
	float: left;
	width: calc(50% - 20px);
}
.form .error.right, .form .error.right, .form .error.right {
	float: right;
	width: calc(50% - 20px);
}

.form input[type="text"].postalCode {
	float: left;
	width: calc(40% - 34px);
}
.form input[type="text"].city {
	float: right;
	width: calc(60% - 44px);
}

.form .labelCheckbox {
	display: block;
	float: left;
	width: 50%;
	margin: 20px 0 0 0;
	padding: 0;
	font-size: 9pt;
	text-align: left;
	cursor: pointer;
	color: var(--textColorLabelCheckbox);
}

.form .buttonAccess {
	width: 100%;
	height: 37px;
	margin: 15px 0 0 0;
	padding: 0 15px;
	text-align: left;
	font-size: var(--fontSizeInputText);
	color: var(--textColorButtonAccess);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderButtonAccess);
	background-color: var(--backgroundColorInput);
	transition: all 0.15s;
	cursor: pointer;
}
.form .buttonAccess:hover {
	background-color: var(--backgroundColorInputHover);
	border: var(--borderButtonAccessHover);
}

.form .barStrength {
	width: calc(100% - 2px);
	height: 3px;
	margin: 5px 1px 0 1px;
	padding: 0;
	background-color: var(--backgroundColorInput);
}
.form .barStrength .bar {
	width: 0;
	height: 3px;
	margin: 0;
	padding: 0;
	transition: all 0.5s;
}
.form .barStrength .bar.low {
	width: calc(100% / 3);
	background-color: var(--backgroundColorBarStrengthLow);
}
.form .barStrength .bar.mid {
	width: calc((100% / 3) * 2);
	background-color: var(--backgroundColorBarStrengthMid);
}
.form .barStrength .bar.strong {
	width: 100%;
	background-color: var(--backgroundColorBarStrengthStrong);
}

.form .forgot {
	display: block;
	float: right;
	width: 50%;
	margin: 21px 0 0 0;
	padding: 0;
	font-size: 9pt;
	text-align: right;
	color: var(--textColor);
	text-decoration: none;
	transition: all 0.15s;
}
.form .forgot:hover {
	text-decoration: underline;
}

.form .marge {
	display: block;
	margin: 0;
	padding: 20px 0 0 0;
}

.form .cancel {
	display: block;
	float: right;
	margin: 0 15px 0 0;
	padding: 12px 20px;
	color: rgb(100, 100, 100);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	font-size: 9pt;
	text-transform: uppercase;
	font-weight: bold;
	background-color: transparent;
	transition: all 0.15s;
}
.form .cancel.block {
	clear: both;
    float: none;
    width: 100%;
    margin: 0;
}
.form .cancel.block.margin {
	margin: 15px 0 0 0;
}
.form .cancel.hide {
	display: none;
}
.form .cancel:hover {
	color: rgb(150, 150, 150);
	text-decoration: underline;
}
.form .cancel:focus {
	outline: none;
}

.form .submit {
	display: block;
	clear: both;
	float: right;
	margin: 0;
	padding: 0 20px;
	line-height: 40px;
	background-color: var(--backgroundColorSubmit);
	color: var(--textColorSubmit);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	font-size: 9pt;
	font-weight: bold;
	text-transform: uppercase;
	transition: all 0.15s;
}
.form .submit:hover {
	background-color: var(--backgroundColorSubmitHover);
}
.form .submit:focus {
	outline: none;
}

.form .delete {
	display: block;
	width: 100%;
	clear: both;
	margin: 0;
	padding: 0;
	line-height: 40px;
	background-color: var(--backgroundColorDelete);
	color: var(--textColorDelete);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	font-size: 9pt;
	font-weight: bold;
	text-transform: uppercase;
	transition: all 0.15s;
}
.form .delete.block {
	float: right;
	width: auto;
	margin: 0;
	padding: 0 20px;
}
.form .delete:hover {
	background-color: var(--backgroundColorDeleteHover);
}
.form .delete:focus {
	outline: none;
}

.form a {
	color: inherit;
	text-decoration: inherit;
}

.form .buttonChoice {
    display: block;
    width: 100%;
    margin: 10px 0 10px 0;
    padding: 0 20px;
    line-height: 40px;
    background-color: var(--backgroundColorSubmit);
    color: var(--textColorSubmit);
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 9pt;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.15s;
}
.form .buttonChoice.spaceTop {
    margin-top: 30px;
}
.form .buttonChoice:hover {
    background-color: var(--backgroundColorSubmitHover);
}
.form .buttonChoice:focus {
    outline: none;
}

.form .add {
	display: block;
	width: 100%;
	height: 39px;
	margin: 5px 0 0 0;
	padding: 0 15px 0 45px;
	color: var(--mainColor);
	border: none;
	background-color: transparent;
	text-align: left;
	font-size: 10pt;
	font-weight: bold;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: left 15px center;
	background-size: 18px 18px;
	background-image: url(../../image/form/add-mainColor.svg);
	transition: all 0.25s;
}
.form .add.marginTop {
	margin-top: 15px;
}
.form .add:hover {
	background-color: var(--backgroundColorNoInputHover);
}

@keyframes bounce {
	from {padding-top: 10px;}
	25%  {padding-top: 5px;}
	50%  {padding-top: 10px;}
	75%  {padding-top: 5px;}
	to   {padding-top: 10px;}
}
