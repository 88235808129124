.table.partnership .line > .name {
	width: 35%;
    max-width: 35%;
}
.table.partnership .line > .postalCode {
	width: 10%;
    max-width: 10%;
}
.table.partnership .line > .city {
	width: 20%;
    max-width: 20%;
}
.table.partnership .line > .type {
	width: 15%;
    max-width: 15%;
}
.table.partnership .line > .nbUser {
	width: 15%;
    max-width: 15%;
}
