.header {
	position: fixed;
	z-index: 10;
	left: 65px;
	top: 0;
	width: calc(100% - 66px);
	height: 60px;
	background-color: var(--backgroundColorMenu);
}

.header .title {
	display: block;
	float: left;
	width: calc(50% - 30px);
	padding: 0 15px;
	margin: 0;
	font-size: 12pt;
	text-transform: uppercase;
	line-height: 60px;
	text-align: left;
	font-weight: bold;
	color: var(--textColor);
}

.header .actions {
	width: 50%;
	display: block;
	float: right;
	margin: 0;
	padding: 0;
}

.header .actions .searchBar {
	overflow: hidden;
	width: 0;
	height: 38px;
	display: block;
	float: right;
	margin: 10px 20px 0 0;
	padding: 3px 0;
	font-size: 11pt;
	line-height: 20px;
	color: var(--textColor);
	border: none;
	border-bottom: 2px solid var(--mainColor);
	background-color: transparent;
	transition: all 0.10s;
}
.header .actions .searchBar:focus {
	outline: none;
}
.header .actions .searchBar.show {
	width: 50%;
	padding: 3px 10px;
}

.header .actions .icon {
	display: none;
	float: right;
	width: 20px;
	height: 20px;
	padding: 0;
	margin: 20px 25px 0 0;
}
.header .actions .icon:first-child {
	margin-right: 15px;
}
.header .actions .icon.close {
	width: 16px;
	height: 16px;
	margin-top: 22px;
}
.header .actions .icon.show {
	display: block;
}
.header .actions .icon svg {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	cursor: pointer;
	transition: all 0.15s;
}
.header .actions .icon:hover svg {
	fill: var(--mainColor);
}
