.table.partnershipUser .line > .lastname {
	width: 20%;
    max-width: 20%;
}
.table.partnershipUser .line > .firstname {
	width: 20%;
    max-width: 20%;
}
.table.partnershipUser .line > .email {
	width: calc(60% - 50px);
    max-width: calc(60% - 50px);
}
