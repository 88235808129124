.overbox.NewProspect .elevator {
	max-height: calc(100vh - 313px);
}

.NewProspect > .steps {
	display: flex;
	flex-flow: row;
	width: calc(100% - 200px);
	margin: 0 100px;
	padding: 20px 0 10px 0;
}

.NewProspect > .steps > svg {
	display: block;
	width: 40px;
	height: 40px;
	transition: all 0.15s;
}
.NewProspect > .steps > svg.done {
	fill: var(--mainColor);
}

.NewProspect > .steps > .line {
	display: block;
	overflow: hidden;
	width: calc(50% - 60px);
	height: 2px;
	margin: 19px 20px;
	padding: 0;
	border-radius: 1px;
	background-color: #1D1D1D;
}
.NewProspect > .steps > .line > .bar {
	display: block;
	width: 0;
	height: 2px;
	margin: 0;
	padding: 0;
	background-color: var(--mainColor);
	transition: all 0.7s;
}
.NewProspect > .steps > .line > .bar.done {
	width: 100%;
}
