.setup {
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
}

.setup .wrapperTables {
	overflow-x: hidden;
	overflow-y: auto;
	float: left;
	width: calc(100% - 320px);
	height: calc(100vh - 167px);
	min-height: calc(100vh - 167px);
	max-height: calc(100vh - 167px);
	margin: 0;
	padding: 0;
	background-color: var(--backgroundColorBis);
}
