.searchBarComponent {
	padding: 15px;
	background-color: var(--whityColor);
}
.searchBarComponent.marginTop {
	padding-top: 15px;
}

.searchBarComponent .select {
    display: block;
    float: left;
    width: 220px;
    margin: 0;
    padding: 10px 15px;
    font-size: 9pt;
	line-height: 22px;
    color: var(--textColorInput);
    border-radius: var(--borderRadiusInputText);
    border: var(--borderInput);
    background-color: var(--backgroundColorInput);
    background-position: right 15px center;
    background-size: 14px 14px;
    background-repeat:  no-repeat;
    background-image: url(../../image/form/down-arrow.svg);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.searchBarComponent .select:hover {
	background-color: var(--backgroundColorInputHover);
}
.searchBarComponent .select:focus {
	outline: none;
	border-color: var(--borderColorInputHover);
	background-color: var(--backgroundColorInputHover);
}

.searchBarComponent .search {
	display: block;
	width: 100%;
	margin: 0;
	padding: 10px 15px;
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.searchBarComponent .search.withSelect {
	width: calc(100% - 265px);
}
.searchBarComponent .search:hover {
	background-color: var(--backgroundColorInputHover);
}
.searchBarComponent .search:focus {
	outline: none;
	border-color: var(--borderColorInputHover);
	background-color: var(--backgroundColorInputHover);
}
