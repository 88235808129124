.setup > .installationPhases {
	display: flex;
	flex-direction: column;
	float: right;
	width: 320px;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 15px;
	background-color: var(--boxColor);
	border-left: 1px solid var(--borderColor);
}

.setup > .installationPhases .phase {
	height: 24px;
	margin: 0;
	padding: 0;
}
.setup > .installationPhases .phase .puce {
	display: block;
	float: left;
	width: 24px;
	height: 24px;
	border-radius: 12px;
	border: 3px solid var(--colorBorderSetupInstallationPhase);
	background-color: transparent;
	transition: all 0.1s;
}
.setup > .installationPhases .phase .puce:not(.unselected) {
	cursor: pointer;
}
.setup > .installationPhases .phase.inProgress .puce {
	border-color: var(--mainColor);
}
.setup > .installationPhases .phase.done .puce {
	border-color: var(--mainColor);
	background-color: var(--mainColor);
}
.setup > .installationPhases .phase .puce:not(.unselected):hover {
	box-shadow: 0 0 6px rgb(150, 150, 150);
}
.setup > .installationPhases .phase > .wrapDescription {
	display: block;
	float: left;
	margin: 0;
	padding: 0 10px 0 15px;
}
.setup > .installationPhases .phase > .wrapDescription > .name {
	display: block;
	margin: 0;
	padding: 0;
	color: var(--textColor);
	text-transform: uppercase;
	line-height: 24px;
	font-size: 10pt;
	font-weight: bold;
	text-align: left;
}
.setup > .installationPhases .phase > .wrapDescription > .description {
	display: block;
	margin: 0;
	padding: 0;
	color: var(--descriptionColor);
	line-height: 20px;
	font-size: 8pt;
	font-weight: bold;
	text-align: left;
}

.setup > .installationPhases .line {
	flex: auto;
	width: 2px;
	margin: 0 0 0 11px;
	background-color: #1D1D1D;
}
.setup > .installationPhases .line.done {
	background-color: var(--mainColor);
}

