.user {
	overflow: hidden;
	width: 45px;
	height: 45px;
	margin: 15px 10px;
	border-radius: 5px;
	background-color: var(--backgroundColorUser);
	box-shadow: var(--boxShadowUser);
	cursor: pointer;
	transition: all 0.15s;
}
.user:hover {
	background-color: var(--backgroundColorUserHover);
}

.user img {
	display: block;
	width: 27px;
	height: 27px;
	margin: 9px;
}
