.table.partnershipStorage .line > .city {
	width: calc(60% - 50px);
	max-width: calc(60% - 50px);
}
.table.partnershipStorage .line > .postalCode {
	width: 20%;
    max-width: 20%;
}
.table.partnershipStorage .line > .country {
	width: 30%;
	max-width: 30%;
}
