.table.setupLicenses .line > .number {
	width: 200px;
    max-width: 200px;
}
.table.setupLicenses .line > .number > .text {
	float: left;
	margin: 0;
	padding: 0;
}

.table.setupLicenses .line > .name {
	width: calc(35% - 150px);
	max-width: calc(35% - 150px);
}

.table.setupLicenses .line > .type {
	width: 200px;
	max-width: 200px;
}

.table.setupLicenses .line > .main {
	width: 150px;
	max-width: 150px;
}
.table.setupLicenses .line > .main > svg {
	width: 18px;
	height: 18px;
}

.table.setupLicenses .line > .code {
	width: 350px;
	max-width: 350px;
}
.table.setupLicenses .line > .code > p {
	margin: 0;
	padding: 0;
	font-weight: bold;
}
.table.setupLicenses .line > .code > p > span {
	padding: 0 0 0 10px;
	font-weight: normal;
	font-style: italic;
	font-size: 10pt;
	color: var(--descriptionColor);
}
