.conversation.clientTicket {
	position: relative;
	height: 100%;
	min-height: 100%;
	color: var(--textColor);
	margin: 0;
	padding: 0;
}

.conversation > .form > .creatorInfo {
	width: calc(100% - 40px);
	height: 50px;
	margin: 0;
	padding: 15px 15px 0 15px;
	font-size: 9pt;
}
.conversation > .form > .creatorInfo > .date {
}
.conversation > .form > .creatorInfo > .fromNow {
	color: rgb(150, 150, 150);
}

.conversation .scrollview {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: calc(100% - 150px);
	margin: 5px 0 0 0;
	padding: 5px 15px;
}

.conversation .scrollview > .history {
	display: block;
	clear: both;
	width: 100%;
	margin: 0;
	padding: 0;
}
.conversation .scrollview > .history > .itemHistory {
	margin: 10px 0 0 0;
	padding: 10px 15px;
	border-radius: 3px;
	background-color: var(--backgroundColorHistoryConversation);
}
.conversation .scrollview > .history > .itemHistory > .creatorInfo {
	width: calc(100% - 40px);
	height: 50px;
	margin: 0;
	padding: 0;
	font-size: 9pt;
}
.conversation .scrollview > .history > .itemHistory > .creatorInfo > .date {
}
.conversation .scrollview > .history > .itemHistory > .creatorInfo > .fromNow {
	color: rgb(150, 150, 150);
}
.conversation .scrollview > .history > .itemHistory > .message {
	margin: 10px 0;
	padding: 0;
}
.conversation .scrollview > .history > .itemHistory > .message p {
	margin: 0;
	padding: 0;
}
.conversation .scrollview > .history > .itemHistory > .message p > span {
	margin: 0 7px;
	padding: 5px 12px;
	border-radius: 3px;
	font-weight: bold;
	color: black;
}

.conversation .write {
	overflow: hidden;
	position: absolute;
	bottom: 0;
	width: 100%;
}
